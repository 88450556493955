import Dialog from '@stimulus-components/dialog';

export default class extends Dialog {
  dialogTargetConnected(element) {
    if (element.dataset.dialogOpen === 'true') {
      this.open();
    }
  }

  closeOnSuccess(event) {
    const { success } = event.detail;
    if (success) { this.close(); }
  }

  close() {
    super.close();
    this.dialogTarget.innerHTML = '';
  }

  forceClose() {
    if (this.hasDialogTarget) this.dialogTarget.close();
  }
}
