import Dropdown from '@stimulus-components/dropdown';
import { createPopper } from '@popperjs/core';

export default class extends Dropdown {
  static values = {
    placement: { type: String, default: 'bottom-start' }
  };

  toggle() {
    super.toggle();
    if (this.transitioned) {
      this.initOrUpdatePopper();
    }
  }

  disconnect() {
    super.disconnect();
    if (this.popper) this.popper.destroy();
  }

  initOrUpdatePopper() {
    if (this.popper) {
      this.popper.update();
      return;
    }

    this.popper = createPopper(this.element, this.menuTarget, {
      placement: this.placementValue,
      modifiers: [{
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 8
        }
      }, {
        name: 'offset',
        options: {
          offset: ({ placement }) => {
            if (placement.includes('left') || placement.includes('right')) return [0, 24];

            return [0, 4];
          }
        }
      }]
    });
  }
}
