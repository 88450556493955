import onmount from 'onmount';
import { MDCDialog } from '@material/dialog';

onmount('[data-js-grading-header]', function () {
  const settingsDialog = document.querySelector('[data-js-grading-settings]');
  if (settingsDialog && !settingsDialog.MDCDialog) {
    settingsDialog.MDCDialog = new MDCDialog(settingsDialog);
    settingsDialog.setAttribute('data-mdc-auto-init-state', 'initialized');
  }

  const settingsTrigger = this.querySelector('[data-js-grading-settings-trigger]');

  const reviewTrigger = this.querySelector('[data-js-review-trigger]');
  const reviewPanel = document.querySelector('[data-js-review-panel]');
  const commentTrigger = this.querySelector('[data-js-comment-trigger]');
  const commentPanel = document.querySelector('[data-js-comment-panel]');

  function handleSettingsTriggerClick() {
    settingsDialog.MDCDialog.open();
  }

  // This can be removed once new_discussions and new_annotations are released out of beta
  function hideScrollButton() {
    const scrollButton = document.querySelector('button.button-nav', 'button-small');
    if (scrollButton) scrollButton.classList.add('invisible');
  }

  function hidePanels() {
    if (reviewPanel) reviewPanel.classList.add('d-none');
    if (reviewTrigger) reviewTrigger.classList.remove('mdc-button--filled', 'mdc-button--unelevated');
    if (commentPanel) commentPanel.classList.add('d-none');
    if (commentTrigger) commentTrigger.classList.remove('mdc-button--filled', 'mdc-button--unelevated');
  }

  function handleReviewTriggerClick() {
    hidePanels();
    if (reviewPanel) reviewPanel.classList.remove('d-none');
    if (reviewTrigger) reviewTrigger.classList.add('mdc-button--filled', 'mdc-button--unelevated');
  }

  function handleCommentTriggerClick() {
    hidePanels();
    hideScrollButton();
    if (commentPanel) commentPanel.classList.remove('d-none');
    if (commentTrigger) commentTrigger.classList.add('mdc-button--filled', 'mdc-button--unelevated');
  }

  if (settingsTrigger) settingsTrigger.addEventListener('click', handleSettingsTriggerClick);

  if (reviewTrigger) reviewTrigger.addEventListener('click', handleReviewTriggerClick);
  if (commentTrigger) commentTrigger.addEventListener('click', handleCommentTriggerClick);
});
